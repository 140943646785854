<template>
    <div ref="wrapper" :class="['input', `input_${size}`, outlined ? 'input_outlined' : '']">
        <div v-if="hasOuterPrefix" class="input__outer-prefix">
            <slot name="outerPrefix" />
        </div>

        <div class="input__input-wrapper">
            <div v-if="hasInnerPrefix" class="input__inner-prefix">
                <slot name="innerPrefix" />
            </div>

            <input ref="input" v-model="text" v-bind="$attrs" v-on="listeners" class="input__input" />

            <div v-if="hasInnerSuffix" class="input__inner-suffix">
                <slot name="innerSuffix" />
            </div>
        </div>

        <div v-if="hasOuterSuffix" class="input__outer-suffix">
            <slot name="outerSuffix" />
        </div>
    </div>
</template>

<!--suppress JSUnusedGlobalSymbols -->
<script>
    export default {
        name: 'InputComponent',
        inheritAttrs: false,
        props: {
            hint: String,
            value: [String, Number],
            size: {
                type: String,
                default: 'medium',
                validator: value => ['large', 'medium', 'small'].includes(value),
            },
            outlined: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            hasInnerPrefix() {
                return !!this.$slots.innerPrefix;
            },
            hasInnerSuffix() {
                return !!this.$slots.innerSuffix;
            },
            hasOuterPrefix() {
                return !!this.$slots.outerPrefix;
            },
            hasOuterSuffix() {
                return !!this.$slots.outerSuffix;
            },
            listeners() {
                delete this.$listeners['input'];
                return this.$listeners;
            },
            text: {
                get() {
                    return this.value;
                },
                set(text) {
                    this.$emit('input', text);
                },
            },
        },
    };
</script>

<style lang="scss" scoped>
    .input {
        position: relative;
        display: flex;
        align-items: center;

        &_outlined &__input {
            background-color: transparent;
            border: 1px solid #1a2030;
        }

        &_medium &__input,
        &_medium &__outer-prefix,
        &_medium &__outer-suffix {
            height: 4.6rem;
            padding: 0 1.5rem;
            font-size: 1.2rem;
        }

        &_medium:has(&__inner-prefix) input {
            padding-left: 5.8rem;
        }

        &_medium:has(&__inner-suffix) input {
            padding-right: 5.8rem;
        }

        &_medium &__inner-prefix,
        &_medium &__inner-suffix {
            width: 5.8rem;

            svg {
                width: 1.8rem;
            }
        }

        &_small &__input,
        &_small &__outer-prefix,
        &_small &__outer-suffix {
            height: 3.6rem;
            padding: 0 1rem;
            font-size: 1.2rem;
        }

        &_small:has(&__inner-prefix) input {
            padding-left: 3.6rem;
        }

        &_small:has(&__inner-suffix) input {
            padding-right: 3.6rem;
        }

        &_small &__inner-prefix,
        &_small &__inner-suffix {
            width: 3.6rem;

            svg {
                width: 1.6rem;
            }
        }
    }

    .input__input-wrapper {
        position: relative;
        display: flex;
        width: 100%;
    }

    .input__input {
        width: 100%;
        border-radius: 0.8rem;
        background-color: #1b2232;
        color: #ccd1de;
        font-family: 'Gotham Book', sans-serif;
        font-weight: 400;
        text-overflow: ellipsis;
        transition: background-color 0.3s;

        &:disabled,
        &:focus {
            background-color: #1b2131;
        }

        &::placeholder {
            color: #585f75;
            text-overflow: ellipsis;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type='number'] {
            appearance: textfield;
            -moz-appearance: textfield;
        }
    }

    .input__outer-prefix,
    .input__outer-suffix {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        border-radius: 0.8rem;
        background-color: #1a2030;
        color: #808ba7;
        font-family: 'Gotham Book', sans-serif;
        font-weight: 325;

        svg {
            width: 1.6rem;
            height: 1.6rem;
            fill: #616e8e;
        }
    }

    .input__outer-prefix {
        border-radius: 0.8rem 0 0 0.8rem;
    }

    .input:has(.input__outer-prefix) input {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input__outer-suffix {
        border-radius: 0 0.8rem 0.8rem 0;
    }

    .input:has(.input__outer-suffix) input {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input__inner-prefix,
    .input__inner-suffix {
        position: absolute;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        transform: translateY(-50%);

        button {
            width: 100%;
            height: 100%;
            cursor: pointer;
            transition: opacity 0.3s;

            &:hover {
                opacity: 0.75;
            }
        }

        svg {
            fill: #353e53;
        }
    }

    .input__inner-prefix {
        left: 0;
    }

    .input__inner-suffix {
        right: 0;
    }

    @media screen and (min-width: 22.5em) {
        .input {
            &_medium &__input,
            &_medium &__outer-prefix,
            &_medium &__outer-suffix {
                height: 4.8rem;
                font-size: 1.3rem;
            }
        }
    }

    @media screen and (min-width: 120em) {
        .input {
            &_medium &__input,
            &_medium &__outer-prefix,
            &_medium &__outer-suffix {
                height: 5.2rem;
                padding: 0 2rem;
                font-size: 1.5rem;
            }

            &_medium:has(&__inner-prefix) input {
                padding-left: 6.2rem;
            }

            &_medium:has(&__inner-suffix) input {
                padding-right: 6.2rem;
            }

            &_medium &__inner-prefix,
            &_medium &__inner-suffix {
                width: 6.2rem;

                svg {
                    width: 2.2rem;
                }
            }

            &_small &__input,
            &_small &__outer-prefix,
            &_small &__outer-suffix {
                height: 4.4rem;
                padding: 0 1.5rem;
                font-size: 1.3rem;
            }

            &_small:has(&__inner-prefix) input {
                padding-left: 5rem;
            }

            &_small:has(&__inner-suffix) input {
                padding-right: 5rem;
            }

            &_small &__inner-prefix,
            &_small &__inner-suffix {
                width: 5rem;

                svg {
                    width: 2rem;
                }
            }
        }

        .input__outer-prefix,
        .input__outer-suffix {
            svg {
                width: 2.2rem;
                height: 2.2rem;
            }
        }
    }
</style>
