<template>
    <button :class="className" v-bind="$attrs" v-on="$listeners">
        <loader v-if="loading" />

        <template v-else>
            <slot />
        </template>
    </button>
</template>

<script>
    import { THEME_COLORS } from '@/constants/themeColors';
    import Loader from '@/components/Loader';

    export default {
        name: 'TextButton',
        components: {
            Loader,
        },
        props: {
            full: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            size: {
                type: String,
                default: 'medium',
                validator: value =>
                    value.split(' ').length === value.split(' ').filter(className => ['medium', 'medium-lg', 'small'].includes(className)).length,
            },
            theme: {
                type: String,
                default: 'primary',
                validator: value => THEME_COLORS.includes(value),
            },
        },
        computed: {
            className() {
                return {
                    button: true,
                    'text-button': true,
                    [this.sizeClass]: true,
                    [`button_${this.theme}`]: true,
                    button_full: this.full,
                    button_disabled: this.loading,
                };
            },
            sizeClass() {
                return this.size
                    .split(' ')
                    .map(item => `button_${item}`)
                    .join(' ');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.8rem;
        color: #edf2ff;
        font-family: 'Gotham Bold', sans-serif;
        font-weight: 700;
        transition: background-color 0.3s, color 0.3s, opacity 0.3s;

        &:disabled,
        &_disabled {
            pointer-events: none;
            opacity: 0.65;
        }

        svg {
            flex-shrink: 0;
            fill: #edf2ff;
            pointer-events: none;
            transition: fill 0.3s;
        }

        ::v-deep .loader {
            border-color: #edf2ff;
        }

        &_primary {
            background-color: #3760cb;

            &:hover {
                background-color: #557ce2;
            }
        }

        &_primary-outlined {
            border: 0.2rem solid #3760cb;
            background-color: #1e284b;
            transition: border-color 0.3s, background-color 0.3s;

            &:hover {
                background-color: #293666;
                border-color: #557ce2;
            }
        }

        &_secondary {
            background-color: #202738;
            color: #a9aebd;

            svg {
                fill: #a9aebd;
            }

            ::v-deep .loader {
                border-color: #a9aebd;
            }

            &:hover {
                background-color: #262c41;
                color: #ccd1de;

                svg {
                    fill: #ccd1de;
                }

                ::v-deep .loader {
                    border-color: #ccd1de;
                }
            }
        }

        &_success {
            background-color: #4ab990;

            &:hover {
                background-color: #68cfb0;
            }
        }

        &_info {
            background-color: #6bd4f5;
            color: #38445a;

            svg {
                fill: #38445a;
            }

            ::v-deep .loader {
                border-color: #38445a;
            }

            &:hover {
                background-color: #9de8ff;
                color: #59749c;

                svg {
                    fill: #59749c;
                }

                ::v-deep .loader {
                    border-color: #59749c;
                }
            }
        }

        &_medium {
            grid-gap: 1rem;
            height: 4.8rem;
            padding: 0 2rem;
            font-size: 1.4rem;

            img,
            svg {
                width: 2rem;
                height: 2rem;
            }

            ::v-deep .loader {
                width: 2rem;
                height: 2rem;
            }
        }

        &_small {
            grid-gap: 0.8rem;
            height: 3.6rem;
            padding: 0 1rem;
            font-size: 1.2rem;

            img,
            svg {
                width: 1.6rem;
                height: 1.6rem;
            }

            ::v-deep .loader {
                width: 1.8rem;
                height: 1.8rem;
            }
        }

        &_full {
            width: 100%;
        }
    }

    @media screen and (min-width: 120em) {
        .button {
            &_medium {
                height: 5.6rem;
                font-size: 1.6rem;

                img,
                svg {
                    width: 2.4rem;
                    height: 2.4rem;
                }

                ::v-deep .loader {
                    width: 2.4rem;
                    height: 2.4rem;
                }
            }

            &_small {
                height: 4.4rem;
                padding: 0 1.5rem;
                font-size: 1.3rem;

                img,
                svg {
                    width: 1.7rem;
                    height: 1.7rem;
                }

                ::v-deep .loader {
                    width: 2.2rem;
                    height: 2.2rem;
                }
            }

            &_medium-lg {
                height: 5.6rem !important;
                font-size: 1.6rem !important;

                img,
                svg {
                    width: 2.4rem !important;
                    height: 2.4rem !important;
                }

                ::v-deep .loader {
                    width: 2.4rem !important;
                    height: 2.4rem !important;
                }
            }
        }
    }
</style>
